import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthComponent } from './health/health.component';

const routes: Routes = [
  {path:'health',component:HealthComponent},
   // Route for the 'auth' module
   { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },

   // Route for the 'admin' module
   { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
 
   // Add a default route or a wildcard route if needed
   { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
 
   // Add a 'not-found' route for any other paths
   { path: '**', redirectTo: '/auth/login' } // You can create a NotFoundComponent for this
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
